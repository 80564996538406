import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavbarToggler, Collapse, Jumbotron,
    Button, Modal, ModalHeader, ModalBody,
     } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { ContactUs } from './ContactUs';




class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isNavOpen: false,
            isModalOpen: false,
        };

        this.toggleNav = this.toggleNav.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }


    handleLogin(event) {
        alert(`Username: ${this.username.value} Password: ${this.password.value} Remember: ${this.remember.checked}`);
        this.toggleModal();
        event.preventDefault();
    }

    render() {
        return (
            <React.Fragment>
                <Jumbotron fluid>
                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-12 col-md-6'>
                                <span>
                                <Link style={{textDecoration: 'none'}} to='/'>
                                    <img src="/assets/images/logo-img.png" height="100" width="350" alt=" Repairs Under one Roof" />
                                </Link>
                                </span>
                                <p className='mx-2 subtext'>
                                    <strong>ADA RECONDITIONING CENTER</strong>
                                </p>
                            </div>
                            <div className='col'></div>
                            <div className='col-12 col-md-5'>
                            <p className='my-0'> Location 1:
                                    <a role="button" style={{fontSize:"1rem"}} className="btn btn-link" href='https://www.google.com/maps/place/5849+Peachtree+Rd+%235,+Atlanta,+GA+30341/@33.9030168,-84.2884532,17z/data=!3m1!4b1!4m5!3m4!1s0x88f509c6dedf8dd3:0xa3f98440ea9f9521!8m2!3d33.9030168!4d-84.2884532'>
                                        <i class="fa fa-map-marker" /> 5849 Peachtree Rd Suite 400, Atlanta GA 30341
                                    </a>
                                </p>
                                {/* <p className='my-0'> Location 2:
                                    <a role="button" style={{fontSize:"1rem"}} className="btn btn-link" href='https://www.google.com/maps/dir//4317+Abbotts+Bridge+Rd+%235+Duluth,+GA+30097/@34.0206098,-84.1629237,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88f59898cc9ead8f:0xdd23f14aeb099415!2m2!1d-84.1629237!2d34.0206098'>
                                        <i class="fa fa-map-marker" /> 4317 Abbotts Bridge Rd Suite 5, Duluth GA 30097
                                    </a>
                                </p> */}
                                <p className='mb-0'> Call Us:
                                    <a role="button" className="btn btn-link" href="tel:+4049942627"><i className="fa fa-phone" /> 1-404-994-2627</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </Jumbotron>

                <Navbar dark sticky="top" expand="md">
                    <div className="container">
                        <NavbarToggler onClick={this.toggleNav} />
                        <Collapse isOpen={this.state.isNavOpen} navbar>
                            <Nav navbar className='mx-auto'>
                                <Button className= 'button' onClick={this.toggleModal}>
                                    <h2><strong>Request Your Service</strong></h2>
                                </Button>
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>

                <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal} size='xl'>
                    <ModalHeader toggle={this.toggleModal}>Request Service</ModalHeader>
                    <ModalBody>
                        <ContactUs></ContactUs>
                    </ModalBody>
                </Modal>

            </React.Fragment>
        );
    }
}

export default Header;