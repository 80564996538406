import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <footer className="site-footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-4 col-sm-2 offset-1">
                        <h5>Links</h5>
                        <ul className="list-unstyled">
                            <li><Link to='/home'>Home</Link></li>
                            <li><Link to='/photos'>Photos</Link></li>
                        </ul>
                    </div>
                    <div className="col-6 col-sm-3 text-center">
                        <h5>Social</h5>
                        <a className="btn btn-social-icon btn-instagram" href="https://www.instagram.com/repairsunderoneroof/"><i className="fa fa-instagram" /></a>{' '}
                        <a className="btn btn-social-icon btn-facebook" href="https://www.facebook.com/profile.php?id=100086118417912"><i className="fa fa-facebook" /></a>{' '}
                        <a className="btn btn-social-icon btn-twitter" href="https://twitter.com/RepairsUnderOne"><i className="fa fa-twitter" /></a>{' '}
                        <a className="btn btn-social-icon btn-google" href="https://www.youtube.com/channel/UCnPG8HplRkOZx_D_o69hfPQ"><i className="fa fa-youtube" /></a> 
                    </div>
                    <div className="col-sm-4 text-center">
                        <a role="button" className="btn btn-link" href="tel:+6783492339"><i className="fa fa-phone" /> 1-678-349-2339</a>
                        <a role="button" className="btn btn-link" href="tel:+4702753199"><i className="fa fa-phone" /> 1-470-275-3199</a>
                        <br />
                        <a role="button" className="btn btn-link" href="mailto:service@repairsunderoneroof.com"><i className="fa fa-envelope-o" /> service@repairsunderoneroof.com</a> <br />
                        <p>Icons from <a href="https://www.flaticon.com/free-icons/">Flaticon</a></p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;