import React, { useState } from 'react';
import { Card, CardImg, CardText, CardBody, Breadcrumb, BreadcrumbItem,  
    Button, Modal, ModalHeader, ModalBody, 
    } from 'reactstrap';
import { Link } from 'react-router-dom';
import { DESCRIPTIONS } from '../shared/descriptions';
import { ContactUs } from './ContactUs';



function ToggleModal(args) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button onClick={toggle}>
        <i className="fa fa-duotone fa-gear fa-lg" /> Request Service
      </Button>
      <Modal isOpen={modal} toggle={toggle} {...args} size='xl'>
        <ModalHeader toggle={toggle}>Your service request</ModalHeader>
        <ModalBody>
            <ContactUs></ContactUs>
        </ModalBody>
      </Modal>
    </div>
  );
}


function RenderService({service}) {
    return (
        <div className='col-md-5 m-1'>
            <Card>
                <CardImg top width="100%" src={service.realImage} alt={service.name} />
                <CardBody>
                    <CardText>{service.description}</CardText>
                </CardBody>
            </Card>
        </div>
    );
}

function RenderDescriptions({descriptions}) {
    if (descriptions) {
        return (
            <div className='col-md-5 m-1'>
                <h4>What we offer</h4>
                {
                    descriptions.map(description => {
                        return (
                            <div key={description.id}>
                                <p>
                                    {description.text}
                                </p>
                                <ToggleModal></ToggleModal>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
    return <div />;
}

function ServiceInfo(props) {
    if (props.service) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                    <Breadcrumb>
                            <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                            <BreadcrumbItem active>{props.service.name}</BreadcrumbItem>
                        </Breadcrumb>
                        <h2>{props.service.name}</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <RenderService service={props.service} />
                    <RenderDescriptions descriptions={props.descriptions} />
                </div>
            </div>
        );
    }
    return <div />;
}

export default ServiceInfo;
