import React, { useState } from 'react';
import { Card, CardImg, CardTitle,   
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    } from 'reactstrap';
import { Link } from 'react-router-dom';
import {SERVICES} from '../shared/services';
import PersuasionCards from './PersuasionCards';
import { getOriginalBodyPadding } from 'reactstrap/lib/utils';
import VideoCarousel from './VideoCarousel';
import { ContactUs } from './ContactUs';
import VidCarousel from './VideoCarousel';





function ToggleModal(args) {
    const [modal, setModal] = useState(false);
  
    const toggle = () => setModal(!modal);
  
    return (
      <div>
        <Button onClick={toggle}>
          <i className="fa fa-duotone fa-gear fa-lg" /> Request Service
        </Button>
        <Modal isOpen={modal} toggle={toggle} size='xl'>
          <ModalHeader toggle={toggle}>Your Service Request</ModalHeader>
          <ModalBody>
              <ContactUs></ContactUs>
          </ModalBody>
        </Modal>
      </div>
    );
  }

function RenderCard({service}) {
    return (
        <Card className='serviceCard'>
            <Link to={`/serviceInfo/${service.id}`} style={{color: 'black', textDecoration: 'none'}} >
                <CardImg width="100%" src={service.image} alt={service.name} />
                <CardTitle className='service-title'>{service.name}</CardTitle>
            </Link>
        </Card>
    );
};

//

function Home(props) {

    const homeStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const vidDiv = {
        padding: '0',
        marginBottom: '50px',
    };

    const home = SERVICES.map(service => {
        return (
            <div key={service.id} className="col-xs-2 text-center">
                <RenderCard service={service} />
            </div>
        );
    });

    return (
        
        <React.Fragment>
            <div className='container-fluid' style={vidDiv}>
                <div className='overlay'></div>
                <VidCarousel></VidCarousel>
                <div className='content'>
                    <h1 className='slogan'>Mechanics that keep you out of the Repair shop</h1>
                    <br></br>
                    <strong>
                        <h4 className='slogan-caption'>Expert Service - Unbeatable Prices - All Under One Roof</h4>
                    </strong>
                </div>
                <div className='container-fluid'>
                    <div className='row '></div>
                </div>
                <div className="container-fluid services">
                    <div className="row text-center">
                        <div className="col">
                            <h2 className='our-services'><strong>Our Services</strong></h2>
                            <hr />
                        </div>
                    </div>
                    <div className="row col-sm-10" style={homeStyle}>
                        {home}
                    </div>
                </div>
            </div>
            <div className='persuasion-cards container'>
                <div className='row' >
                    <PersuasionCards />
                </div>
            </div>
            <div className='google-map container col-md-12'>
                <div className='row text-center'>
                    <div className='col'>
                        <a role="button" className="btn btn-link" href='https://www.google.com/maps/dir//4317+Abbotts+Bridge+Rd+%235+Duluth,+GA+30097/@34.0206098,-84.1629237,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88f59898cc9ead8f:0xdd23f14aeb099415!2m2!1d-84.1629237!2d34.0206098'>
                            <h2>Atlanta Location</h2>
                        </a>
                        <iframe style={{width: "100%", height: "500px" }} 
                            src="https://maps.google.com/maps?q=5849%20Peachtree%20Rd%20suite%20400,%20Atlanta,%20GA%2030341&t=&z=13&ie=UTF8&iwloc=&output=embed"                             frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                        </iframe>
                    </div>
                </div>
            </div>
            <div className='faded-purp container-fluid'>
                <div className='founder-card row col-md-8 text-center'>
                    <div>
                        <h2>Meet the Team</h2>
                        <p> We grew up as a car lovers and decided to combine
                            our passion for vehicles and entrepreneurship to start 
                            several successful bussinesses. One of our most well known 
                            bussinesses is <strong>Repairs Under One Roof</strong>. We decided to start Repairs 
                            Under One Roof with the goal of building a team of mechanics who
                            shared our passion for cars. We have succeeded and Repairs Under 
                            One Roof is well on its way to becoming one of
                            the best repair shops around.
                        </p>
                    </div>
                    {/* <div className='founder-img col-md-6'>
                        <img src='/assets/real-images/founderimg.png' height="400" width="400"/>
                    </div> */}
                </div>
            </div>
            <div className='purp-info container-fluid d-flex justify-content-center'>
                <div className='row'>
                    <div className='col-sm-3'>
                        <h2>Hours of Operation</h2>
                        <p>Monday-Friday<span><h3>9am-6pm</h3></span></p>
                        <hr/>
                    </div>
                    <div className='col-sm-3'>
                        <h2>How Can We Help?</h2>
                        <p>Our service team is available 5 days a week:</p>
                        <p>Monday - Friday 9 AM to 6 PM </p>
                        <span>
                            <ToggleModal />
                        </span>
                    </div>
                    <div className='col-sm-6'>
                        <h2>About Us</h2>
                        <p>Repairs Under One Roof was started by a group of passionate car lovers
                            with over 20 years in the auto-repair industry
                        </p>
                        <br />
                        <p>We pride ourselves on providing professional 
                            and affordable service that keeps your car out of the repair shop
                        </p>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}



export default Home;