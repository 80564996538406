import { DESCRIPTIONS } from '../shared/descriptions';
import { SERVICES } from '../shared/services';


export const initialState = {
    services: SERVICES,
    descriptions: DESCRIPTIONS,
};

export const Reducer = (state = initialState, action) => {
    return state;
};