import React, { Component } from 'react';
import ServiceInfo from './ServiceInfoComponent';
import Home from './HomeComponent';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        services: state.services,
        descriptions: state.descriptions,
    };
};

class Main extends Component {

    render() {

        const HomePage = () => {
            return (
                <Home 
                    service={this.props.services}
                />
            );
        };

        const ServiceWithId = ({match}) => {
            return (
                <ServiceInfo service={this.props.services.filter(service => service.id === +match.params.serviceId)[0]}
                    descriptions={this.props.descriptions.filter(description => description.serviceId === +match.params.serviceId)} />
            );
        };

        return (
            <div>
                <Header />
                <Switch>
                    <Route path='/home' component={HomePage} />
                    <Route path='/ServiceInfo/:serviceId' component={ServiceWithId} />
                    <Redirect to='/home' component={HomePage} />
                </Switch>
                <Footer />
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(Main));