export const SERVICES = [
    {
        id: 0,
        name: "Body Work and Painting",
        image: "/assets/images/car-painting.png",
        realImage: "/assets/real-images/auto-detailing.jpg",
        description: ""
    },
    {
        id: 1,
        name: "Mechanical Repair",
        image: "/assets/images/car-repair.png",
        realImage: "/assets/real-images/mechanical-repair.jpg",
        description: ""
    },
    {
        id: 2,
        name: "Upholstery",
        image: "/assets/images/seat.png",
        realImage: "/assets/real-images/upholstery.jpg",
        description: ""
    },
    {
        id: 3,
        name: "Wheel Repair",
        image: "/assets/images/tire.png",
        realImage: "/assets/real-images/wheel-repair.jpg",
        description: ""
    },
    {
        id: 4,
        name: "Paintless Dent Repair",
        image: "/assets/images/broken.png",
        realImage: "/assets/real-images/paintless-dent-repair.jpg",
        description: ""
    },
    {
        id: 5,
        name: "Auto Detailing",
        image: "/assets/images/workshop.png",
        realImage: "/assets/real-images/bodywork.jpg",
        description: ""
    },
    {
        id: 6,
        name: "Wrapping and Window Tinting",
        image: "/assets/images/car-door.png",
        realImage: "/assets/real-images/wrapping-and-window-tinting.jpg",
        description: ""
    },
    {
        id: 7,
        name: "Interior Reconditioning",
        image: "/assets/images/vacuum.png",
        realImage: "/assets/real-images/interior-reconditioning.jpg",
        description: ""
    },
    {
        id: 8,
        name: "Glass Repair and Replacement",
        image: "/assets/images/glass.png",
        realImage: "/assets/real-images/windshield-repair-and-replacement.jpg",
        description: ""
    },
    {
        id: 9,
        name: "Headlight Restoration",
        image: "/assets/images/headlight.png",
        realImage: "/assets/real-images/headlight-restoration.jpeg",
        description: ""
    }
];