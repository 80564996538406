import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import {
    FormGroup, Input, Label,
    } from 'reactstrap';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7ej522b', 'template_80y2su4', form.current, '2gxQuba3U30FCc9gb')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
          alert('your message is sent');
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  return (
    <div className='container-fluid contact-us'>
        <form ref={form} onSubmit={sendEmail}>
            <div className='row pt-3 mx-auto'>
                <Label>Fullname</Label>
                <div className='col-xs form-group  mx-auto'>
                    <input type='text' className='form-control info-form' name='name'></input>
                </div>
                <Label>Phone Number</Label>
                <div className='col-xs form-group mx-auto'>
                    <input type='text' className='form-control info-form' name='number'></input>
                </div>
            </div>
            <div className='row pt-3 mx-auto'>
                <Label>Email</Label>
                <div className='col-xs-6 form-group mx-auto'>
                    <input type='email' className='form-control' name='email'></input>
                </div>
                <div className='col-6 form-group mx-auto'>
                    <FormGroup
                        check
                        inline
                    >
                        <Label className='col-xs mx-auto' check>Have you purchased a car here before?</Label>
                        <input type="checkbox" className="form-check-input" name="prevCustomer" />
                        <Label check className='col-xs-2'>
                            Yes
                        </Label>
                    </FormGroup>
                    <FormGroup
                        check
                        inline
                    >
                        <input type="checkbox" className="form-check-input" name="notPrevCustomer" />
                        <Label check className='col-xs-2'>
                            No
                        </Label>
                    </FormGroup>
                </div>
            </div>
            <div className='row pt-3 mx-auto text-center'>
                <div className='col-4 form-group'>
                    <Label>Vin Number</Label>
                    <input type='text' className='form-control' name='Vin'></input>
                </div>
                <div className='col-8 form-group pt-4'>
                    <Label>Attach Images of your damages to you email</Label>
                    <a role="button" style={{color: 'purple'}} className="btn btn-link" href="mailto:service@repairsunderoneroof.com"><i className="fa fa-envelope-o" /> service@repairsunderoneroof.com</a> <br />
                    {/* <input id="exampleFile" name="file" type="file"/> */}
                </div>
            </div>
            <div className='row pt-3 mx-auto text-center'>
                <div className='col'>
                    <Label>Trim</Label>
                    <input type='text' className='form-control' name='Trim'></input>
                </div>
                <div className='col'>
                    <Label>Year</Label>
                    <input type='text' className='form-control' name='carYear'></input>
                </div>
                <div className='col'>
                    <Label>Make</Label>
                    <input type='text' className='form-control' name='Make'></input>
                </div>
                <div className='col'>
                    <Label>Model</Label>
                    <input type='text' className='form-control' name='Model'></input>
                </div>
            </div>
            <div className='row pt-3'>
                <div className="col-4 form-group text-center">
                    <Label>Your Service Request</Label>
                    <Input id="selectService" name="serviceSelect" type="select" >
                        <option>Body Work and Painting</option>
                        <option>Mechanical Repair</option>
                        <option>Wheel Repair</option>
                        <option>Paintless Dent Repair</option>
                        <option>Auto Detailing</option>
                        <option>Wrapping and Window Tinting</option>
                        <option>Interior Reconditioning</option>
                        <option>Glass Repair and Replacement</option>
                        <option>Headlight Restoration</option>
                    </Input>
                </div>
                <div className="col-4 form-group text-center">
                    <Label>Additional Services</Label>
                    <Input id="selectService" multiple name="additonalService" type="select" >
                        <option>Body Work and Painting</option>
                        <option>Mechanical Repair</option>
                        <option>Wheel Repair</option>
                        <option>Paintless Dent Repair</option>
                        <option>Auto Detailing</option>
                        <option>Wrapping and Window Tinting</option>
                        <option>Interior Reconditioning</option>
                        <option>Glass Repair and Replacement</option>
                        <option>Headlight Restoration</option>
                    </Input>
                </div>
            </div>  
            <div className='row pt-4 mx-auto'>
                <Label>Message</Label>
                <div className='col-8 form-group pt-2 mx-auto'>
                    <textarea className='form-control' cols='30' rows='8' placeholder='Describe Your Service Needs' name='message'></textarea>
                </div>
            </div>              
            <div className='col-4 form-group pt-3 mx-auto'>
                <input type='submit' className='btn btn-dark' value= 'send message'></input>
            </div>
        </form>
    </div>
  );
};

