import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';


const items = [
  {
    src: '/assets/videos/vid4.mp4',
    
    key: 1,
  },
  {
    src: '/assets/videos/vid1.mp4',
    
    key: 2,
  },
  {
    src: '/assets/videos/vid2.mp4',
    
    key: 3,
  },
  {
    src: '/assets/videos/vid3.mp4',
    
    key: 4,
  },
  {
    src: '/assets/videos/vid5.mp4',
    
    key: 5,
  },
  {
    src: '/assets/videos/vid6.mp4',
    
    key: 6,
  },
  {
    src: '/assets/videos/vid7.mp4',
    
    key: 7,
  },
  {
    src: '/assets/videos/vid8.mp4',
    
    key: 8,
  },
  {
    src: '/assets/videos/vid9.mp4',
    
    key: 9,
  },
  {
    src: '/assets/videos/vid10.mp4',
    key: 10,
  },
];

function VideoCarousel(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="carousel-vid"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <video src={item.src} alt={item.altText} autoPlay loop muted />
        <CarouselCaption
          className="carousel-slogan"
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...args}
      ride='carousel'
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
    </Carousel>
  );
}

export default VideoCarousel;