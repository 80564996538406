import React, { Component } from 'react';
import {  Card, CardImg, CardText, CardTitle,  } from 'reactstrap';

function PersuasionCards(props) {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-4'>
                    <Card className= 'text-center border-0 mr-5'>
                        <CardImg
                        alt="Card image cap"
                        src="/assets/images/savebig.png"
                        top
                        width="100%"
                        />
                        <CardTitle className= 'my-3'>
                            Affordable rates
                        </CardTitle>
                        <CardText>
                            Keep the dents out of your vehicle and your wallet with our affordable care
                        </CardText>
                    </Card>
                </div>
                <div className='col-md-4'>
                    <Card className= 'text-center border-0 mr-5 ml-5'>
                    <CardImg
                    alt="Card image cap"
                    src="/assets/images/dependable.png"
                    top
                    width="100%"
                    />
                        <CardTitle className= 'my-3'>
                            Your Satisfaction Is Our Goal
                        </CardTitle>
                        <CardText>
                            Look no further for auto repairs, our mechanics bring more than 20 year of experience
                        </CardText>
                    </Card>
                </div>
                <div className='col-md-4'>
                    <Card className= "text-center border-0 ml-5">
                        <CardImg
                        alt="Card image cap"
                        src="/assets/images/repsonsive.png"
                        top
                        width="100%"
                        />
                        <CardTitle className= 'my-3'>
                            Expert Auto Service
                        </CardTitle>
                        <CardText>
                            Our goal is to respond with the customer within two hours
                        </CardText>
                    </Card>
                </div>
            </div>
        </div>
        
    );
}

export default PersuasionCards;

           
            
            