export const DESCRIPTIONS = 
[
    {
        id: 0,
        serviceId: 0,
        text: "Our repair service offers professional and affordable care. As much as we love our customers, our goal is to keep them out of the repair shop as much as possible. Our paint packages will give your car back its luster.",
    },
    {
        id: 1,
        serviceId: 1,
        text: "You name it and we can fix it. Our mechanical work ranges from tune-ups and inspections, break pad and wheel bearing fixing, to engine replacements and transmission work",
    },
    {
        id: 2,
        serviceId: 2,
        text: "We peform custom car upholstery and interior restoration. We install seats, carpets, floor mats and much more. We can handle all your interior needs ",
    },
    {
        id: 3,
        serviceId: 3,
        text: "Our capable mechanics offer high quality wheel repair services including but not limited to, cosmetic repairs, luxury car services, structual damage repairs, and powdercoating",
    },
    {
        id: 4,
        serviceId: 4,
        text: "Restore your vehicle to its original shape with Paintless Dent Repair(pdr). This service removes dents while keeping the car manufacturer's paintwork untouched.",
    },
    {
        id: 5,
        serviceId: 5,
        text: "Keep you vehicle looking its best with our auto detailing  services which include, exterior washes, interior vacuuming, door jams, window cleaning and many more.",
    },
    {
        id: 6,
        serviceId: 6,
        text: "We offer a wide range of world class wrapping and tinting services which include vehicle wraps, window tinting, custom designs and graphics and many more.",
    },
    {
        id: 7,
        serviceId: 7,
        text: "We can restore your vehicles interior, with our reconditioning services that include stain/odor removal, carpet replacement, vinyl/leather repair and much more",
    },
    {
        id: 8,
        serviceId: 8,
        text: "Our glass repair services range form  fixing most minor cracks and chips, to replacing window glass and windshields",
    },
    {
        id: 9,
        serviceId: 9,
        text: "We restore aged headlights and remove components that may be worn or damaged. We sand and polish lenses to prevent future debris form entering the light. You headlights will be revived and brightened",
    },
]